import React from "react";
import { Link, useParams } from "react-router-dom";
import Container from "react-bootstrap/Container";
import { useState } from "react";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import { Icon } from "@iconify/react";
import Modal from "react-bootstrap/Modal";
import Badge from "react-bootstrap/Badge";
import InputGroup from "react-bootstrap/InputGroup";
import Table from "react-bootstrap/Table";
import Accordion from "react-bootstrap/Accordion";
import { getuserOrderId } from "../Services/serviceApi";
import { useCookies } from 'react-cookie';
import { useEffect } from "react";

// Add Components
import Header from "../Components/Header";
import Footer from "../Components/Footer";

const OrderSuccess = () => {
  // const { orderId } = useParams();
  // const orderid =atob(orderId)
  const [orderId, setOrderId] = useState('');
  const [orderPrice, setOrderPrice] = useState('');
  const [promotionPrice, setPromotionPrice] = useState('');


  const [cookies] = useCookies(['SESSIONINFO'], ['LOGGEDINFO']);
  const userId = (cookies.LOGGEDINFO != '' && cookies.LOGGEDINFO != undefined) ? cookies.LOGGEDINFO : cookies.SESSIONINFO;

  useEffect(() => {
    console.log("ordersuccess userId",userId);
    
    const fetchOrderId = () => {
      getuserOrderId(userId)
        .then(response => {
          console.log("ordersuccess",response);
          setOrderId(response.data.data.order_id);
          setOrderPrice(response.data.data.cart_total_price);
          setPromotionPrice(response.data.data.discount_amount);
        })
      return
    }
    fetchOrderId();
    return ;
  }, [])

  return (
    <div>
      <Header />
      <section className="order-success mt-space">
        <Container>
          <Row>
            <Col lg={12} md={12} xs={12}>
              <div className="order-box text-center">
                <div className="success-icon">
                  <span className="iconify">
                    <Icon icon="teenyicons:tick-circle-solid" />
                  </span>
                </div>
                <h4>
                  Thank you for your order. We’ll send a confirmation <br /> when
                  your order shiped.
                </h4>
                <p>
                  Order ID:
                  <strong>
                    {/* <Link to={`/order-details?order_id=${orderId}`}>{orderId}</Link> */}
                    <Link to={`/order-details?order_id=${btoa(orderId)}`}> {orderId}</Link>
                  </strong>
                </p>
                <p>
                  Your order total price: ₹
                  <strong>
                    {orderPrice}
                  </strong>
                </p>
                {promotionPrice > 0 && (
                  <p>
                  Promotion offer price: -
                  <strong>
                    {promotionPrice}
                  </strong>
                </p>
                )}
                {promotionPrice > 0 && (
                  <p>
                  Total paid amount: ₹
                  <strong>
                    {orderPrice-promotionPrice}
                  </strong>
                </p>
                )}
                <div class="para-text">
                  <p>
                    If you would like to view the status of your order or make
                    any changes to it, please visit
                  </p>
                </div>
                <div className="order-button mt-3">
                  <Link
                    variant="primary"
                    to="/my-orders"
                    className="btn btn-success btn-lg btn-order me-3 "
                  >
                    My Orders
                  </Link>
                  <Link
                    variant="primary"
                    to="/Home"
                    className="btn btn-home btn-lg btn-order"
                  >
                    Home
                  </Link>
                </div>
              </div>
            </Col>
          </Row>
        </Container>
        {/* loader */}
        {/* <div className="loader-box">
      <div class="lds-ripple"><div></div><div></div></div>
      <div className="loader-text">
          <p>Loading...</p>
        </div>
      </div> */}
        {/* loader end */}
      </section>

      <Footer />
    </div>
  );
};

export default OrderSuccess;
